<template>
  <section>
    <b-row class="match-height">
      <b-col
        sm="12"
        md="12"
        lg="8"
        xl="8"
      >
        <br>
        <b-tabs pills>
          <b-tab
            title="Visão geral"
            active
          >
            <b-card>
              <h3>Sobre este curso</h3>
              <p>{{ curso.description }}</p>
              <hr size="50">
              <h2>Detalhes do curso</h2>
              <b-card-text>
                Nível de experiência: {{ curso.level }}
              </b-card-text>
              <b-card-text>
                Idiomas:
                {{ curso.language }},
              </b-card-text>
              <b-card-text>
                Legendas:
                {{ curso.caption }}
              </b-card-text>
              <b-card-text>
                Aulas: 0000
              </b-card-text>
              <!-- <hr size="50"> -->
              <!-- <h2>Descrição: </h2>
              <p>{{ curso.description }}</p> -->
            </b-card>
          </b-tab>
          <b-tab title="Professores">
            <b-card>
              integrating...
              <!-- <div v-if="speakers.length > 0">
                <CarouselInterval :speakers="speakers" />
              </div> -->
            </b-card>
          </b-tab>
        </b-tabs>
      </b-col>
      <b-col
        sm="12"
        md="12"
        lg="4"
        xl="4"
      >
        <b-button
          v-if="!isOnCart"
          variant="primary"
          style="margin: 2px"
          @click="addToCart([{
            course_id: curso.id,
            congress_id: false,
            name: curso.title,
            amount: 1,
            value: parseFloat(curso.amount),
            img: curso.thumb
          }])"
        >
          Adicionar ao carrinho
        </b-button>

        <b-button
          v-if="isOnCart"
          variant="success"
          style="margin: 2px"
          :href="`/cursos`"
        >
          Continuar comprando
        </b-button>

        <b-button
          v-if="isOnCart"
          variant="primary"
          style="margin: 2px"
          :href="`/pagamento`"
        >
          Ir para carrinho
        </b-button>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import CarouselInterval from '@/components/carousel/CarouselInterval.vue'
import CoursesServices from './coursesServices'
import { mapActions } from 'vuex'

export default {
  components: {
    // CarouselInterval,
  },
  data() {
    return {
      baseStorage: process.env.VUE_APP_API_BASE,
      link_watch: null,
      annotation_text: '',
      question_text: '',
      form: {
        time: '',
        text: ''
      },
      speakers: [
        {
          photo: null,
          name_badge: 'Teste',
          mini_curriculo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent efficitur urna metus, eu convallis enim sollicitudin quis. Sed semper faucibus suscipit. Morbi eleifend bibendum porttitor. Pellentesque vulputate neque sit amet libero cursus, ac molestie tortor lobortis. Nunc molestie felis interdum neque finibus, vel sagittis enim tempus. In porta feugiat metus id mollis.',
          instagram: 'idjfdif'
        }
      ],
      curso: {},
      item: {},
      isOnCart: false,
    }
  },
  computed: {
    items() {
      return this.$store.state.payment.items
    }
  },
  watch: {},
  async mounted() {
    await CoursesServices().getCourse({ course_slug: this.$route.params.course_slug }).then(api => {
      this.curso = api.data.response.course
      this.item = {
        course_id: this.curso.id,
        name: this.curso.title,
        amount: 1,
        value: parseFloat(this.curso.amount)
      }
    })
    // eslint-disable-next-line prefer-const
    let items = JSON.parse(localStorage.getItem('items'))

    if (items.length > 0) {
      // eslint-disable-next-line array-callback-return
      items.some(element => {
        console.log(this.item)
        if (element.course_id === this.item.course_id) {
          this.isOnCart = true
        }
      })
    }
  },
  methods: {
    ...mapActions('payment', [
      'ActionAddItem'
    ]),
    async addToCart(obj) {
      await this.ActionAddItem(obj[0])
      localStorage.setItem('items', JSON.stringify(this.items))
      this.isOnCart = true
    },
    makeToast(variant = null, text_title, text) {
      this.$bvToast.toast(text, {
        title: text_title,
        variant,
        solid: true,
      })
    },
  },
}
</script>
<style scoped>
.collapse-title{
  font-size: 18px !important;
}
</style>
